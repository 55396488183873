import Heading from '@csv/styleguide/src/elements/heading/heading';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import CSVLogoWhite from './../images/csv-logo-white.svg';
import SponsorkliksLogo from './../images/sponsorkliks-logo.svg';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  grid-gap: 16px;
  padding: 80px 16px 16px;
  background: #001450;
  color: white;
  justify-content: center;
  justify-items: center;
  margin-top: 3rem;

  > div {
    width: 100%;
    max-width: 400px;
  }

  h2 {
    color: white;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  img {
    width: 150px;
    max-width: 100%;
    height: auto;
  }

  a {
    color: white;
    font-weight: bold;
    &:visited {
      color: white;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <div>
          <Heading variant="h6" as="h2">
            Over CSV Handbal
          </Heading>
          <Link to="/" rel="home" title="CSV Handbal">
            <img src={CSVLogoWhite} alt="Logo CSV Handbal Castricum" />
          </Link>
          <p>
            CSV Handbal in Castricum is een fijne, leuke club waar leden van
            alle leeftijden hun tijd graag doorbrengen. Dé plek waar iedereen
            kan (leren) handballen, plezier en ambitie hand in hand gaan en
            iedereen zijn steentje bijdraagt.
          </p>
        </div>
      </div>
      <div>
        <Heading variant="h6" as="h2">
          Snel naar:
        </Heading>
        <ul className="footer__nav">
          <li>
            <Link to="teams">Competitie</Link>
          </li>
          <li>
            <a
              href="https://clubs.deventrade.com/nl/csv-handbal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clubshop
            </a>
          </li>
          <li>
            <Link to="contact">Contact &amp; route</Link>
          </li>
          <li>
            <Link to="over-csv/lidmaatschap/">Lidmaatschap</Link>
          </li>
          <li>
            <Link to="over-csv/privacy">Privacy</Link>
          </li>
        </ul>
      </div>
      <div>
        <Heading variant="h6" as="h2">
          Steun de club via sponsorkliks
        </Heading>
        <a
          className="footer__sponsorkliks"
          href="https://www.sponsorkliks.com/products/shops.php?club=3830"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={SponsorkliksLogo} alt="Sponsorkliks logo" />
        </a>
        <p>
          Wil jij CSV ook GRATIS sponsoren? Zorg er dan voor dat je voortaan
          jouw online aankopen doet via de SponsorKlikspagina van CSV. Voor jouw
          een kleine moeite, voor CSV directe inkomsten. Stel je eens voor
          wanneer alle leden dit zouden doen? Vertel dit daarom door aan andere
          mensen die CSV een warm hart toedragen.
        </p>
      </div>
    </StyledFooter>
  );
};

export default Footer;

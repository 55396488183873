/**
 * Basic Layout for all pages
 * contains Header, Footer and a main section in which
 * all children will render
 */

import React, { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import ThemeProvider from '@csv/styleguide/src/theme/theme-provider';

interface LayoutProps {
  children: ReactNode;
}

// @TODO rename this to something more semantic
const Layout = ({ children }: LayoutProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // @TODO Layout wrapping component in styleguide to avoid dependency on material-ui?
  return (
    <ThemeProvider>
      <>
        <Header />
        <main>{children}</main>
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;

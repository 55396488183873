import typographySettings from './typography';
import colors from './colors/colors';

const fontSizes = typographySettings.baseFontScale.reverse();

const breakpoints = ['40em', '52em', '64em'];

const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};

export default {
  mode: 'light',
  fontSizes,
  fonts: {
    default: 'Camphor',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 600,
    heavy: 900,
  },
  space: [
    '0rem',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
  ],
  colors: {
    lightBlues: colors.COLOR_BLUE_LIGHT,
    darkBlues: colors.COLOR_BLUE_DARK,
    black: colors.COLOR_BLACK,
    white: colors.COLOR_WHITE,
    grey: colors.COLOR_GREY,
    warning: colors.COLOR_WARNING,
    success: colors.COLOR_SUCCESS,
    danger: colors.COLOR_DANGER,
  },
  radii: {
    default: 4,
  },
  shadows: {
    default: '1px 1px 6px rgba(0,0,0,.1)',
  },
  breakpoints,
  mediaQueries,
};

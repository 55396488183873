import styled, { css } from 'styled-components';

export const BaseContainerStyles = css`
  max-width: 88rem;
  margin-left: auto;
  margin-right: auto;
`;

export const BaseContainerMargin = css`
  margin-bottom: ${({ theme }) => theme.space[3]};

  ${props => `${props.theme.mediaQueries.medium} {
    margin-bottom: ${props.theme.space[8]};
  }`}
`;

// Containers have 12px horizontal padding,
// 32px on larger screens

// make sure margins between items in grid is
// half of the horizontal container padding
// i.e. 16px for 32px

export const BaseContainerHorizontalPadding = css`
  padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};

  ${props => `${props.theme.mediaQueries.medium} {
    padding-left: ${props.theme.space[8]};
    padding-right: ${props.theme.space[8]};
  }`}
`;

export const BaseContainerVerticalPadding = css`
  padding-top: ${({ theme }) => theme.space[8]};
  padding-bottom: ${({ theme }) => theme.space[8]};
`;

const Container = styled.div`
  ${BaseContainerStyles}
  ${BaseContainerMargin}
  ${BaseContainerHorizontalPadding}
  ${BaseContainerVerticalPadding}
`;

export default Container;

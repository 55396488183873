import styled from 'styled-components';
import typographySettings from '../../theme/typography';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps {
  variant: HeadingVariant;
  as: HeadingVariant;
  hasMargin?: boolean;
}

const Heading = styled.div<HeadingProps>`
  font-weight: 600;
  font-size: ${props => typographySettings[props.variant].fontSize};
  line-height: ${props => typographySettings[props.variant].lineHeight};
  margin-bottom: ${props =>
    props.hasMargin ? typographySettings[props.variant].marginBottom : 0};
`;

Heading.defaultProps = {
  hasMargin: true,
};

export default Heading;

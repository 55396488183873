export const COLOR_BLUE_LIGHT = {
  900: '#2041A3',
  800: '#2560C2',
  700: '#2871D4',
  600: '#2A83E8',
  500: '#2B91F6',
  400: '#45A1F8',
  300: '#65B2F9',
  200: '#90C8FB',
  100: '#BBDDFD',
  50: '#E3F1FE',
};

export const COLOR_BLUE_DARK = {
  900: '#001450',
  800: '#0e2466',
  700: '#172d72',
  600: '#20357d',
  500: '#263c85',
  400: '#475795',
  300: '#6773a6',
  200: '#909abf',
  100: '#bcc1d9',
  50: '#e4e6ef',
};

export const COLOR_BLACK = '#0a0a0a';
export const COLOR_GREY = '#555555';
export const COLOR_WHITE = '#FFFFFF';

export const COLOR_WARNING = '#ffaa00';
export const COLOR_SUCCESS = '#3ecf8e';
export const COLOR_DANGER = '#de350b';

// @TODO CLEAN UP
export const BOX_SHADOW = '1px 1px 6px rgba(0,0,0,.1)';
export const GREY_BG = '#f5f6f840'; // ?

export default {
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_DARK,
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_GREY,
  COLOR_WARNING,
  COLOR_SUCCESS,
  COLOR_DANGER,
};

import algoliasearch from 'algoliasearch/lite';
import {
  createRef,
  default as React,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import styled, { ThemeProvider, css } from 'styled-components';
import SearchBox from './search-box';
import SearchResult from './search-result';

const events = [`mousedown`, `touchstart`];
const useClickOutside = (ref, onClickOutside) => {
  const isOutside = element => !ref.current || !ref.current.contains(element);
  const onClick = event => {
    if (isOutside(event.target)) {
      onClickOutside();
    }
  };
  useEffect(() => {
    for (const event of events) {
      document.addEventListener(event, onClick);
    }
    return () => {
      for (const event of events) document.removeEventListener(event, onClick);
    };
  });
};

const theme = {
  foreground: '#050505',
  background: 'white',
  faded: '#888',
};

const StyledSearchRoot = styled.div`
  position: relative;
  margin: 0.6rem;
`;

const open = css`
  width: 10em;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.6em;
  padding-left: 1.6em;
`;
const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`;

const StyledSearchBox = styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  .SearchInput {
    border: ${({ hasFocus }) => (hasFocus ? '1px solid #e5e8ed' : 'none')};
    font-size: 1em;
    transition: 250ms;
    border-radius: 4px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: ${({ theme }) => theme.foreground};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }
  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
  }
`;

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 1rem 2rem;
  border-radius: 4px;
  background: ${({ theme }) => theme.background};
`;
const StyledSearchResult = styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}
  .HitCount {
    display: flex;
    justify-content: flex-end;
  }
  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      padding: 0;
    }
    li.ais-Hits-item {
      margin-bottom: 1em;
      a {
        color: ${({ theme }) => theme.foreground};
        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;
    svg {
      width: 70px;
    }
  }
`;

export default function Search({ indices }) {
  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      ),
    [],
  );
  useClickOutside(rootRef, () => setFocus(false));
  return (
    <ThemeProvider theme={theme}>
      <StyledSearchRoot ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
          <StyledSearchResult
            show={query && query.length > 0 && hasFocus}
            indices={indices}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
  );
}

import Shevy from 'shevyjs';

export const HMTL_FONT_SIZE = '16px';

const typographyOptions = {
  baseFontSize: '1rem',
  baseLineHeight: 1.5,
  baseFontScale: 'minorThird',
  addMarginBottom: true,
  proximity: false,
  proximityFactor: 0.85,
};

const typographySettings = new Shevy(typographyOptions);

export default typographySettings;
// /* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/3a841e");

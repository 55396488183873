import { ThemeProvider as SCThemeProvider } from 'styled-components';
import React from 'react';
import { DecoratorFunction, StoryFn } from '@storybook/addons';

import theme from './theme';
import GlobalStyles from './global-styles';

const ThemeProvider = ({ children }: { children: React.ReactChild }) => (
  <SCThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      {children}
    </>
  </SCThemeProvider>
);

export const ThemeDecorator: DecoratorFunction = (storyFn: StoryFn) => (
  <ThemeProvider>
    <>{storyFn()}</>
  </ThemeProvider>
);

export default ThemeProvider;

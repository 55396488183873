import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { FaSearch as SearchIcon } from '@react-icons/all-files/fa/FaSearch';

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <input
        className="SearchInput"
        type="text"
        placeholder="Zoeken"
        aria-label="Zoeken"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <SearchIcon className="SearchIcon" />
    </form>
  ),
);

import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import typographySettings from './typography';

import CamphorRegularTtf from './../assets/webfonts/3A841E_0_0.ttf';
import CamphorRegularWoff2 from './../assets/webfonts/3A841E_0_0.woff2';
import CamphorRegularWoff from './../assets/webfonts/3A841E_0_0.woff';
import CamphorRegularSvg from './../assets/webfonts/3A841E_0_0.svg';
import CamphorRegularEot from './../assets/webfonts/3A841E_0_0.eot';

import CamphorItalicTtf from './../assets/webfonts/3A841E_1_0.ttf';
import CamphorItalicWoff2 from './../assets/webfonts/3A841E_1_0.woff2';
import CamphorItalicWoff from './../assets/webfonts/3A841E_1_0.woff';
import CamphorItalicSvg from './../assets/webfonts/3A841E_1_0.svg';
import CamphorItalicEot from './../assets/webfonts/3A841E_1_0.eot';

import CamphorBoldTtf from './../assets/webfonts/3A841E_2_0.ttf';
import CamphorBoldWoff2 from './../assets/webfonts/3A841E_2_0.woff2';
import CamphorBoldWoff from './../assets/webfonts/3A841E_2_0.woff';
import CamphorBoldSvg from './../assets/webfonts/3A841E_2_0.svg';
import CamphorBoldEot from './../assets/webfonts/3A841E_2_0.eot';

import CamphorHeavyTtf from './../assets/webfonts/3A841E_3_0.ttf';
import CamphorHeavyWoff2 from './../assets/webfonts/3A841E_3_0.woff2';
import CamphorHeavyWoff from './../assets/webfonts/3A841E_3_0.woff';
import CamphorHeavySvg from './../assets/webfonts/3A841E_3_0.svg';
import CamphorHeavyEot from './../assets/webfonts/3A841E_3_0.eot';

import CamphorLightTtf from './../assets/webfonts/3A841E_4_0.ttf';
import CamphorLightWoff2 from './../assets/webfonts/3A841E_4_0.woff2';
import CamphorLightWoff from './../assets/webfonts/3A841E_4_0.woff';
import CamphorLightSvg from './../assets/webfonts/3A841E_4_0.svg';
import CamphorLightEot from './../assets/webfonts/3A841E_4_0.eot';

export const GlobalStyles = createGlobalStyle`
  @font-face {
   font-family: "Camphor";
   font-display: fallback;
   font-weight: normal;
   font-style: normal;
   src: url("${CamphorRegularEot}");
   src: url("${CamphorRegularEot}#iefix") format("embedded-opentype"),
     url("${CamphorRegularWoff2}") format("woff2"),
     url("${CamphorRegularWoff}") format("woff"),
     url("${CamphorRegularTtf}") format("truetype"),
     url("${CamphorRegularSvg}wf") format("svg");
 }

 @font-face {
   font-family: "Camphor";
   font-display: fallback;
   font-weight: normal;
   font-style: italic;
   src: url("${CamphorItalicEot}");
   src: url("${CamphorItalicEot}#iefix") format("embedded-opentype"),
     url("${CamphorItalicWoff2}") format("woff2"),
     url("${CamphorItalicWoff}") format("woff"),
     url("${CamphorItalicTtf}") format("truetype"),
     url("${CamphorItalicSvg}wf") format("svg");
 }

 @font-face {
   font-family: "Camphor";
   font-display: fallback;
   font-weight: bold;
   font-style: normal;
   src: url("${CamphorBoldEot}");
   src: url("${CamphorBoldEot}#iefix") format("embedded-opentype"),
     url("${CamphorBoldWoff2}") format("woff2"),
     url("${CamphorBoldWoff}") format("woff"),
     url("${CamphorBoldTtf}") format("truetype"),
     url("${CamphorBoldSvg}wf") format("svg");
 }

 @font-face {
   font-family: "Camphor";
   font-display: fallback;
   font-weight: 900;
   font-style: normal;
   src: url("${CamphorHeavyEot}");
   src: url("${CamphorHeavyEot}#iefix") format("embedded-opentype"),
     url("${CamphorHeavyWoff2}") format("woff2"),
     url("${CamphorHeavyWoff}") format("woff"),
     url("${CamphorHeavyTtf}") format("truetype"),
     url("${CamphorHeavySvg}wf") format("svg");
 }

 @font-face {
   font-family: "Camphor";
   font-display: fallback;
   font-weight: 300;
   font-style: normal;
   src: url("${CamphorLightEot}");
   src: url("${CamphorLightEot}#iefix") format("embedded-opentype"),
     url("${CamphorLightWoff2}") format("woff2"),
     url("${CamphorLightWoff}") format("woff"),
     url("${CamphorLightTtf}") format("truetype"),
     url("${CamphorLightSvg}wf") format("svg");
 }

 ${styledNormalize}

 html {
   font-size: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
   box-sizing: border-box;
 }

  *, *:before, *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
 
  body {
    font-family: "Camphor", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,Oxygen-Sans, Ubuntu,Cantarell, "Helvetica Neue", sans-serif; 
    font-size: ${typographySettings.body.fontSize};
    line-height: ${typographySettings.body.lineHeight};
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 600;
  }

  h1 {
    font-size: ${typographySettings.h1.fontSize};
    line-height: ${typographySettings.h1.lineHeight};
    margin-bottom: ${typographySettings.h1.marginBottom};
  }
  h2 {
    font-size: ${typographySettings.h2.fontSize};
    line-height: ${typographySettings.h2.lineHeight};
    margin-bottom: ${typographySettings.h2.marginBottom};
  }
  h3 {
    font-size: ${typographySettings.h3.fontSize};
    line-height: ${typographySettings.h3.lineHeight};
    margin-bottom: ${typographySettings.h3.marginBottom};
  }
  h4 {
    font-size: ${typographySettings.h4.fontSize};
    line-height: ${typographySettings.h4.lineHeight};
    margin-bottom: ${typographySettings.h4.marginBottom};
  }
  h5 {
    font-size: ${typographySettings.h5.fontSize};
    line-height: ${typographySettings.h5.lineHeight};
    margin-bottom: ${typographySettings.h5.marginBottom};
  }
  h6 {
    font-size: ${typographySettings.h6.fontSize};
    line-height: ${typographySettings.h6.lineHeight};
    margin-bottom: ${typographySettings.h6.marginBottom};
  }
`;

export default GlobalStyles;

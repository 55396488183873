import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Link from 'gatsby-link';
import styled from 'styled-components';
import CSVLogo from './../images/csv-logo.svg';
import { BaseContainerStyles } from '@csv/styleguide/src/elements/container/container';
import Search from './search';

export const menuLinksQuery = graphql`
  query MenuLinksQuery {
    site {
      siteMetadata {
        menuLinks {
          link
          name
        }
      }
    }
  }
`;

const Toolbar = styled.div`
  display: flex;
  height: 52px;
  background: white;
  border-bottom: 1px solid #ededed;
`;

const SiteNavigation = styled.nav`
  ${BaseContainerStyles}
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 24px;
  overflow-x: auto;
  position: relative;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  li {
    margin: 0 1rem;
  }

  a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #555555;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }

    &:visisted {
      color: #555555;
    }

    &[aria-current='page'] {
      color: #2041a3;
    }
  }

  @media screen and (max-width: 84rem) {
    padding: 0 30px;

    &::before,
    &::after {
      content: '';
      width: 30px;
      height: 52px;
      background-size: 100% 52px;
      background-repeat: no-repeat;
      background-position: left center;
      /* display: inline-block; */
      display: none;
      position: fixed; // @FIXME fixed can only be used in conjunction with sticky parent
      z-index: 1; // @FIXME use theme constant?
      top: 0px;
    }

    &::before {
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 90%
      );
    }
  }
`;

const LogoWrapper = styled(props => <Link {...props} />)`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    height: 40px;
    width: auto;
  }
`;

const Header = () => {
  const {
    site: {
      siteMetadata: { menuLinks: menuLinksData },
    },
  } = useStaticQuery(menuLinksQuery);

  return (
    <Toolbar>
      <SiteNavigation>
        <LogoWrapper to="/">
          <img src={CSVLogo} alt="CSV Logo" />
        </LogoWrapper>
        <ul>
          {menuLinksData.map((link, i) => (
            <li key={i}>
              {link.link.includes('http') ? (
                <a href={link.link} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              ) : (
                <Link to={link.link}>{link.name}</Link>
              )}
            </li>
          ))}
        </ul>
      </SiteNavigation>
      <Search indices={[{ name: `Sanity content`, title: `Content` }]} />
    </Toolbar>
  );
};

export default Header;

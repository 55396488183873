import styled, { css } from 'styled-components';
import Container from '../../elements/container/container';
import React from 'react';
import Heading from '../../elements/heading/heading';

export const PageHeader = styled.header`
  background: #2041a3;
`;

export const PageHeaderContainer = styled(Container)<{
  disableVerticalMargin?: boolean;
}>`
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 64px;

  @media screen and (min-width: 520px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  ${({ disableVerticalMargin }) =>
    disableVerticalMargin
      ? css`
          margin-bottom: 0 !important;
        `
      : css``}
`;

export const PageHeaderTitle = styled(Heading)`
  color: white;
  font-weight: 900;
  margin-bottom: 0.5rem;
`;

export const PageHeaderSubTitle = styled.span`
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 14px;
`;

export const PageHeaderByline = styled.p`
  font-weight: 300;
  color: white;
  opacity: 0.9;
  font-size: 14px;
  max-width: 40rem;
  margin-bottom: 0;
`;

export interface PageHeaderCompositionProps {
  title: string;
  subTitle?: string;
  byline?: string;
  disableVerticalMargin?: boolean;
}
export function PageHeaderComposition({
  title,
  subTitle,
  byline,
  disableVerticalMargin = false,
}: PageHeaderCompositionProps) {
  return (
    <PageHeader>
      <PageHeaderContainer disableVerticalMargin={disableVerticalMargin}>
        {subTitle && <PageHeaderSubTitle>{subTitle}</PageHeaderSubTitle>}
        <PageHeaderTitle as="h1" variant="h1">
          {title}
        </PageHeaderTitle>
        {byline && <PageHeaderByline>{byline}</PageHeaderByline>}
      </PageHeaderContainer>
    </PageHeader>
  );
}
